import React, { Fragment, useEffect, useState } from "react";
import "./Products.css";
import "../Home/Home.css";
import "./Home.css";
import { useSelector, useDispatch } from "react-redux"; // getProduct ko directly call nahi kar sakte isi liye useSelector aur useDispatch ka use kar rahe hai.
import { clearErrors, getProduct } from "../../actions/productAction"; // getProduct ek variable bana hua hai file me dekho productAction.js.
import Loader from "../layout/Loader/Loader";
import ProductCard from "../Home/ProductCard";
import Offer from "../Home/Offer.js";
import Pagination from "react-js-pagination";
import { useAlert } from "react-alert"; // ye ek libarary hai jisme se hum { useAlert } lerahe hai.
import Typography from "@material-ui/core/Typography"; 
import MetaData from "../layout/MetaData";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const categories = [
  "Dryfruit",
  "Cosmetic",
  "Baby",
  "Grocery",
  "DailyNeed Items",
  "Crockery",
  "toys",
  "Plastics Appliance",
];

const Products = ({ match }) => {
  const slidee = [
    "inside4.jpg",
    "inside3.jpg",
    "inside1.jpg",
    "inside6.jpg",
    "inside5.jpg",
  ];

  const images = [
    "tmartfront.jpg",
    // "inside1.jpg",
    // "inside3.jpg",
    // "inside4.jpg",
    // "inside5.jpg",
  ];

  const categoryBackgrounds = {
    Dryfruit: "/image/ds.png",
    Cosmetic: "/image/cs.jpg",
    Baby: "/image/dds.png",
    Grocery: "/image/gs.png",
    "DailyNeed Items": "/image/ns.jpg",
    Crockery: "/image/css.png",
    toys: "/image/ts.png",
    "Plastics Appliance": "/image/ps.jpg",
  };

  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderSettings = {
    dots: true, // Display dots navigation
    infinite: true, // Infinite loop
    speed: 100, // Transition speed in milliseconds (ms)
    slidesToShow: 1, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll at a time
    autoplay: true, // Autoplay slides
    autoplaySpeed: 3000, // Delay between slides in milliseconds (ms)
    afterChange: (current) => setCurrentSlide(current), // Callback function after slide change
  };

  const dispatch = useDispatch(); // upar wala { clearErrors, getProduct } ye call horaha hai yaha aur ye call ko humne ek dispatch wale variable me store kar liye.. 

  const alert = useAlert(); // yaha call horaha hai.
//ye pagination keliye hai initially 1 select rahenga pagination me.
  const [currentPage, setCurrentPage] = useState(1); 
  const [price, setPrice] = useState([0, 25000]);
  const [category, setCategory] = useState("");

  const [ratings, setRatings] = useState(0);

  const {
    products,
    loading,
    error,
    productsCount,
    resultPerPage,
    filteredProductsCount,
  } = useSelector((state) => state.products); // ye 6 data productReducer.js file se araha hai. webpage par show hone se pahile ye data inspect ke redux tab me jata hai phir hum ise useSelector ki madad se webpage me bulate hai.

  const keyword = match.params.keyword;

  const setCurrentPageNo = (e) => {
    setCurrentPage(e);
  };

  const priceHandler = (event, newPrice) => {
    setPrice(newPrice);
  };
  let count = filteredProductsCount;

  useEffect(() => {
    if (error) {
      alert.error(error); // agar error aati hai to upar wala const alert = useAlert(); call hojayenga.
      dispatch(clearErrors()); // ye upar import mese aarahi hai.
    }
//productAction.js me ek function bana hua hai getProduct ke naam ka. price upar useState se araha hai.
    dispatch(getProduct(keyword, currentPage, price, category, ratings));//keyword upar wala hai. 
  }, [dispatch, keyword, currentPage, price, category, ratings, alert, error]);
  // { clearErrors, getProduct } yaha se jo getProduct araha hai usse humne yaha call kar rahe hai.


    // Filter products with offerr between 5 to 10
    const filteredProducts = products.filter(
      (product) => product.offerr >= 1 && product.offerr <= 10
    );

    const filteredProducts20 = products.filter(
      (product) => product.offerr >= 11 && product.offerr <= 20
    );

    const filteredProducts30 = products.filter(
      (product) => product.offerr >= 21 && product.offerr <= 30
    );

    const filteredProducts40 = products.filter(
      (product) => product.offerr >= 31 && product.offerr <= 40
    );

    const filteredProducts50 = products.filter(
      (product) => product.offerr >= 41 && product.offerr <= 50
    );

    const filteredProductsAbove = products.filter(
      (product) => product.offerr > 50
    );

  return (
    <Fragment>
      {loading ? ( // ? means pahile loading hongi. 
        <Loader /> // loader wale folder me loader.js naam ki ek file hai.
      ) : ( // : means else agar loading nahi hai to.
        <Fragment>
          <MetaData title="PRODUCTS - Tz MART" />
          <div className="slider-container">
            <Slider {...sliderSettings}>
              {slidee.map((imagee, index) => (
                <div key={index}>
                  <div
                    className="banner"
                    style={{
                      backgroundImage: `url(/imagee/${imagee})`,
                      // opacity: index === currentSlide ? 1 : 0,
                    }}
                  >
                    <div className="banner-content">
                      {/* <div className="banner" style={{ margin: 0, padding: 0 }}> */}
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: "24px",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        Welcome to Tz MART
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>

          <br></br>
          <Typography
            variant="h5"
            style={{
              textAlign: "center",
              marginTop: "16px",
              fontWeight: "bold",
            }}
          >
            Top Categories
          </Typography>
          <ul
            className="categoryBox"
            style={{
              listStyleType: "none",
              padding: 0,
              margin: 0,
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {categories.map((category) => (
              <li
                className="category-link"
                key={category}
                onClick={() => setCategory(category)}
                style={{
                  width: "calc(25% - 20px)",
                  marginBottom: "20px",
                }}
              >
                <Card
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    height: "80px",
                    transition: "transform 0.3s ease",
                    backgroundImage: `url(${categoryBackgrounds[category]})`, // Replace "/path/to/abc.png" with the actual path to your image
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <CardContent>{/* {category} */}</CardContent>
                </Card>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "green",
                    textAlign: "center",
                  }}
                >
                  {category}
                </Typography>
              </li>
            ))}
          </ul>
                  
          <h2 className="homeHeading" style={{ fontWeight: "bold", marginBottom: "5px", marginTop: "5px"   }}>
            Offers
          </h2>
          <div style={{ fontWeight: "bold", marginBottom: "5px", marginTop: "5px"   }} className="container" id="container">
            {products &&
              products
                .filter((product) => product.category === "Offer")
                .slice(0, 8) // Take only the first 8 offers
                .map((product) => (
                  <Offer
                    key={product._id}
                    product={product}
                    targetCategory="Offer" // Replace with your actual target category
                  />
                ))}
          </div>

          <h2 className="productsHeading" style={{ fontWeight: "bold" }}>
            All Products
          </h2>

          <div className="products">
            {products &&
              products
                .filter((product) => product.category !== "Offer")
                .map((product) => (
                  <ProductCard key={product._id} product={product} />
                ))}
          </div>

          <h2 className="homeHeading" style={{ fontWeight: "bold" }}>Save Upto 10 👇</h2>
          <div className="container" id="container">
            {filteredProducts.map((product) => (
              <ProductCard
                key={product._id}
                product={product}
                // targetCategory="DailyNeed Items"
              />
            ))}
          </div>

          <h2 className="homeHeading" style={{ fontWeight: "bold", marginTop:"1px" }}>Save Upto 20 👇</h2>
          <div className="container" id="container">
            {filteredProducts20.map((product) => (
              <ProductCard
                key={product._id}
                product={product}
                // targetCategory="DailyNeed Items"
              />
            ))}
          </div>

          <h2 className="homeHeading" style={{ fontWeight: "bold" }}>Save Upto 30 👇</h2>
          <div className="container" id="container">
            {filteredProducts30.map((product) => (
              <ProductCard
                key={product._id}
                product={product}
                // targetCategory="DailyNeed Items"
              />
            ))}
          </div>

          <h2 className="homeHeading" style={{ fontWeight: "bold" }}>Save Upto 40 👇</h2>
          <div className="container" id="container">
            {filteredProducts40.map((product) => (
              <ProductCard
                key={product._id}
                product={product}
                // targetCategory="DailyNeed Items"
              />
            ))}
          </div>

          <h2 className="homeHeading" style={{ fontWeight: "bold" }}>Save Upto 50 👇</h2>
          <div className="container" id="container">
            {filteredProducts50.map((product) => (
              <ProductCard
                key={product._id}
                product={product}
                // targetCategory="DailyNeed Items"
              />
            ))}
          </div>

          <h2 className="homeHeading" style={{ fontWeight: "bold" }}>Save Above 50 👇</h2>
          <div className="container" id="container">
            {filteredProductsAbove.map((product) => (
              <ProductCard
                key={product._id}
                product={product}
                // targetCategory="DailyNeed Items"
              />
            ))}
          </div>

          <div
            style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}
          >
            <div className="row" style={{ marginBottom: "4px" }}>
              <div className="col-lg-12">
                <div className="section-head-style-one">
                  <h2>What are you looking for?</h2>
                  <p>We have a variety of categories available</p>
                  <Slider {...sliderSettings}>
                    {images.map((image, index) => (
                      <img
                        key={index}
                        src={`/image/${image}`}
                        alt={`Image ${index + 1}`}
                        style={{ width: "10%", height: "10%" }}
                      />
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>

          {resultPerPage < count && ( //agar for example products 8 hai to pagination nahi dikhenga, agar 9 rahe to pagination dikhenga. resultPerPage productController.js se araha hai.
            <div className="paginationBox">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={resultPerPage}
                totalItemsCount={productsCount}
                onChange={setCurrentPageNo}
                nextPageText="Next"
                prevPageText="Prev"
                firstPageText="1st"
                lastPageText="Last"
                itemClass="page-item"
                linkClass="page-link"
                activeClass="pageItemActive"
                activeLinkClass="pageLinkActive"
              />
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Products;





// import React, { Fragment, useEffect, useState } from "react";
// import "./Products.css";
// import "../Home/Home.css";
// import "./Home.css";
// import { useSelector, useDispatch } from "react-redux";
// import { clearErrors, getProduct } from "../../actions/productAction";
// import Loader from "../layout/Loader/Loader";
// import ProductCard from "../Home/ProductCard";
// import Offer from "../Home/Offer.js";
// import Pagination from "react-js-pagination";
// import { useAlert } from "react-alert";
// import Typography from "@material-ui/core/Typography";
// import MetaData from "../layout/MetaData";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";

// const categories = [
//   "Dryfruit",
//   "Cosmetic",
//   "Baby",
//   "Grocery",
//   "DailyNeed Items",
//   "Crockery",
//   "toys",
//   "Plastics Appliance",
// ];

// const Products = ({ match }) => {
//   const slidee = [
//     "inside4.jpg",
//     "inside3.jpg",
//     "inside1.jpg",
//     "f1.jpg",
//     "inside5.jpg",
//   ];

//   const images = [
//     "tmartfront.jpg",
//     // "inside1.jpg",
//     // "inside3.jpg",
//     // "inside4.jpg",
//     // "inside5.jpg",
//   ];

//   const categoryBackgrounds = {
//     Dryfruit: "/image/ds.png",
//     Cosmetic: "/image/cs.jpg",
//     Baby: "/image/dds.png",
//     Grocery: "/image/gs.png",
//     "DailyNeed Items": "/image/ns.png",
//     Crockery: "/image/css.png",
//     toys: "/image/ts.png",
//     "Plastics Appliance": "/image/ps.jpg",
//   };

//   const [currentSlide, setCurrentSlide] = useState(0);

//   const sliderSettings = {
//     dots: true, // Display dots navigation
//     infinite: true, // Infinite loop
//     speed: 100, // Transition speed in milliseconds (ms)
//     slidesToShow: 1, // Number of slides to show at a time
//     slidesToScroll: 1, // Number of slides to scroll at a time
//     autoplay: true, // Autoplay slides
//     autoplaySpeed: 3000, // Delay between slides in milliseconds (ms)
//     afterChange: (current) => setCurrentSlide(current), // Callback function after slide change
//   };

//   const dispatch = useDispatch();

//   const alert = useAlert();

//   const [currentPage, setCurrentPage] = useState(1);
//   const [price, setPrice] = useState([0, 25000]);
//   const [category, setCategory] = useState("");

//   const [ratings, setRatings] = useState(0);

//   const {
//     products,
//     loading,
//     error,
//     productsCount,
//     resultPerPage,
//     filteredProductsCount,
//   } = useSelector((state) => state.products);

//   const keyword = match.params.keyword;

//   const setCurrentPageNo = (e) => {
//     setCurrentPage(e);
//   };

//   const priceHandler = (event, newPrice) => {
//     setPrice(newPrice);
//   };
//   let count = filteredProductsCount;

//   useEffect(() => {
//     if (error) {
//       alert.error(error);
//       dispatch(clearErrors());
//     }

//     dispatch(getProduct(keyword, currentPage, price, category, ratings));
//   }, [dispatch, keyword, currentPage, price, category, ratings, alert, error]);

//   return (
//     <Fragment>
//       {loading ? (
//         <Loader />
//       ) : (
//         <Fragment>
//           <MetaData title="PRODUCTS - Tz MART" />
//           <div className="slider-container">
//             <Slider {...sliderSettings}>
//               {slidee.map((imagee, index) => (
//                 <div key={index}>
//                   <div
//                     className="banner"
//                     style={{
//                       backgroundImage: `url(/imagee/${imagee})`,
//                       // opacity: index === currentSlide ? 1 : 0,
//                     }}
//                   >
//                     <div className="banner-content">
//                       {/* <div className="banner" style={{ margin: 0, padding: 0 }}> */}
//                       <p
//                         style={{
//                           margin: 0,
//                           padding: 0,
//                           fontSize: "24px",
//                           color: "white",
//                           fontWeight: "bold",
//                         }}
//                       >
//                         Welcome to Tz MART
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </Slider>
//           </div>

//           <br></br>
//           <Typography
//             variant="h5"
//             style={{
//               textAlign: "center",
//               marginTop: "16px",
//               fontWeight: "bold",
//             }}
//           >
//             Top Categories
//           </Typography>
//           <ul
//             className="categoryBox"
//             style={{
//               listStyleType: "none",
//               padding: 0,
//               margin: 0,
//               display: "flex",
//               flexWrap: "wrap",
//               justifyContent: "space-between",
//             }}
//           >
//             {categories.map((category) => (
//               <li
//                 className="category-link"
//                 key={category}
//                 onClick={() => setCategory(category)}
//                 style={{
//                   width: "calc(25% - 20px)",
//                   marginBottom: "20px",
//                 }}
//               >
//                 <Card
//                   style={{
//                     border: "1px solid #ccc",
//                     borderRadius: "8px",
//                     height: "80px",
//                     transition: "transform 0.3s ease",
//                     backgroundImage: `url(${categoryBackgrounds[category]})`, // Replace "/path/to/abc.png" with the actual path to your image
//                     backgroundSize: "cover",
//                     backgroundRepeat: "no-repeat",
//                     backgroundPosition: "center",
//                   }}
//                 >
//                   <CardContent>{/* {category} */}</CardContent>
//                 </Card>
//                 <Typography
//                   variant="body1"
//                   style={{
//                     fontWeight: "bold",
//                     fontSize: "16px",
//                     color: "green",
//                     textAlign: "center",
//                   }}
//                 >
//                   {category}
//                 </Typography>
//               </li>
//             ))}
//           </ul>
                  
//           <h2 className="homeHeading" style={{ fontWeight: "bold", marginBottom: "5px", marginTop: "5px"   }}>
//             Offers
//           </h2>
//           <div style={{ fontWeight: "bold", marginBottom: "5px", marginTop: "5px"   }} className="container" id="container">
//             {products &&
//               products
//                 .filter((product) => product.category === "Offer")
//                 .slice(0, 8) // Take only the first 8 offers
//                 .map((product) => (
//                   <Offer
//                     key={product._id}
//                     product={product}
//                     targetCategory="Offer" // Replace with your actual target category
//                   />
//                 ))}
//           </div>

//           <h2 className="productsHeading" style={{ fontWeight: "bold" }}>
//             Products
//           </h2>

//           <div className="products">
//             {products &&
//               products
//                 .filter((product) => product.category !== "Offer")
//                 .map((product) => (
//                   <ProductCard key={product._id} product={product} />
//                 ))}
//           </div>

//           <div
//             style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}
//           >
//             <div className="row" style={{ marginBottom: "4px" }}>
//               <div className="col-lg-12">
//                 <div className="section-head-style-one">
//                   <h2>What are you looking for?</h2>
//                   <p>We have a variety of categories available</p>
//                   <Slider {...sliderSettings}>
//                     {images.map((image, index) => (
//                       <img
//                         key={index}
//                         src={`/image/${image}`}
//                         alt={`Image ${index + 1}`}
//                         style={{ width: "10%", height: "10%" }}
//                       />
//                     ))}
//                   </Slider>
//                 </div>
//               </div>
//             </div>
//           </div>

//           {resultPerPage < count && (
//             <div className="paginationBox">
//               <Pagination
//                 activePage={currentPage}
//                 itemsCountPerPage={resultPerPage}
//                 totalItemsCount={productsCount}
//                 onChange={setCurrentPageNo}
//                 nextPageText="Next"
//                 prevPageText="Prev"
//                 firstPageText="1st"
//                 lastPageText="Last"
//                 itemClass="page-item"
//                 linkClass="page-link"
//                 activeClass="pageItemActive"
//                 activeLinkClass="pageLinkActive"
//               />
//             </div>
//           )}
//         </Fragment>
//       )}
//     </Fragment>
//   );
// };

// export default Products;