import React, { Fragment, useState } from "react";
import "./Header.css";
import { SpeedDial, SpeedDialAction } from "@material-ui/lab";
import Backdrop from "@material-ui/core/Backdrop";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { logout } from "../../../actions/userAction";
import { useDispatch, useSelector } from "react-redux";

const UserOptions = ({ user }) => {
  const { cartItems } = useSelector((state) => state.cart);

  const [open, setOpen] = useState(false);
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();

  const options = [
    { icon: <ListAltIcon />, name: "Orders", func: orders },
    { icon: <PersonIcon />, name: "Profile", func: account },
    {
      icon: (
        <ShoppingCartIcon
          style={{ color: cartItems.length > 0 ? "tomato" : "unset" }} //ek se zyada hoto tamatar color hojayenga.
        />
      ),
      name: `Cart(${cartItems.length})`,
      func: cart,
    },
    { icon: <ExitToAppIcon />, name: "Logout", func: logoutUser },
  ];

  if (user.role === "admin") {
    options.unshift({ //unshift array ka method hai. dashboard ko starting me add kardenga.
      icon: <DashboardIcon />,
      name: "Dashboard",
      func: dashboard,
    });
  }

  function dashboard() {
    history.push("/admin/dashboard"); // jaise hi dashboard par click karenga to dashbaord wale page par chale jayenga.
  }

  function orders() {
    history.push("/orders");
  }
  function account() {
    history.push("/account");
  }
  function cart() {
    history.push("/cart");
  }
  function logoutUser() {
    dispatch(logout());
    alert.success("Logout Successfully");
  }
// ye file jo right side me dikhenga login hone ke baad jisme logout, order aur cart etc rahenge.
  return (
    <Fragment> 
      <Backdrop open={open} style={{ zIndex: "10" }} />
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        style={{ zIndex: "11" }}
        open={open}
        direction="down"
        className="speedDial"
        icon={
          <img
            className="speedDialIcon"
            src={user.avatar.url ? user.avatar.url : "/Profile.png"} //user ki profile ayengi yaha.
            alt="Profile"
          />
        }
      >
        {options.map((item) => (
          <SpeedDialAction
            key={item.name}
            icon={item.icon}
            tooltipTitle={item.name}
            onClick={item.func} // click karne par upar ke options me se mil jayenge.
            tooltipOpen={window.innerWidth <= 600 ? true : false}
          />
        ))}
      </SpeedDial>
    </Fragment>
  );
};

export default UserOptions;







// import React, { Fragment, useState } from "react";
// import "./Header.css";
// import { SpeedDial, SpeedDialAction } from "@material-ui/lab";
// import Backdrop from "@material-ui/core/Backdrop";
// import DashboardIcon from "@material-ui/icons/Dashboard";
// import PersonIcon from "@material-ui/icons/Person";
// import ExitToAppIcon from "@material-ui/icons/ExitToApp";
// import ListAltIcon from "@material-ui/icons/ListAlt";
// import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
// import { useHistory } from "react-router-dom";
// import { useAlert } from "react-alert";
// import { logout } from "../../../actions/userAction";
// import { useDispatch, useSelector } from "react-redux";

// const UserOptions = ({ user }) => {
//   const { cartItems } = useSelector((state) => state.cart);

//   const [open, setOpen] = useState(false);
//   const history = useHistory();
//   const alert = useAlert();
//   const dispatch = useDispatch();

//   const options = [
//     { icon: <ListAltIcon />, name: "Orders", func: orders },
//     { icon: <PersonIcon />, name: "Profile", func: account },
//     {
//       icon: (
//         <ShoppingCartIcon
//           style={{ color: cartItems.length > 0 ? "tomato" : "unset" }}
//         />
//       ),
//       name: `Cart(${cartItems.length})`,
//       func: cart,
//     },
//     { icon: <ExitToAppIcon />, name: "Logout", func: logoutUser },
//   ];

//   if (user.role === "admin") {
//     options.unshift({
//       icon: <DashboardIcon />,
//       name: "Dashboard",
//       func: dashboard,
//     });
//   }

//   function dashboard() {
//     history.push("/admin/dashboard");
//   }

//   function orders() {
//     history.push("/orders");
//   }
//   function account() {
//     history.push("/account");
//   }
//   function cart() {
//     history.push("/cart");
//   }
//   function logoutUser() {
//     dispatch(logout());
//     alert.success("Logout Successfully");
//   }

//   return (
//     <Fragment>
//       <Backdrop open={open} style={{ zIndex: "10" }} />
//       <SpeedDial
//         ariaLabel="SpeedDial tooltip example"
//         onClose={() => setOpen(false)}
//         onOpen={() => setOpen(true)}
//         style={{ zIndex: "11" }}
//         open={open}
//         direction="down"
//         className="speedDial"
//         icon={
//           <img
//             className="speedDialIcon"
//             src={user.avatar.url ? user.avatar.url : "/Profile.png"}
//             alt="Profile"
//           />
//         }
//       >
//         {options.map((item) => (
//           <SpeedDialAction
//             key={item.name}
//             icon={item.icon}
//             tooltipTitle={item.name}
//             onClick={item.func}
//             tooltipOpen={window.innerWidth <= 600 ? true : false}
//           />
//         ))}
//       </SpeedDial>
//     </Fragment>
//   );
// };

// export default UserOptions;
