import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension"; // inspect tab me connect karne keliye.
import {
  newProductReducer,
  newReviewReducer,
  productDetailsReducer,
  productReducer,
  productReviewsReducer,
  productsReducer,
  reviewReducer,
} from "./reducers/productReducer";

import {
  allUsersReducer,
  forgotPasswordReducer,
  profileReducer,
  userDetailsReducer,
  userReducer,
} from "./reducers/userReducer";

import { cartReducer } from "./reducers/cartReducer";
import {
  allOrdersReducer,
  myOrdersReducer,
  newOrderReducer,
  orderDetailsReducer,
  orderReducer,
} from "./reducers/orderReducer";

const reducer = combineReducers({
  products: productsReducer, //yaha hum productsReducer.js file ko hum use kar rahe hai.
  productDetails: productDetailsReducer,
  user: userReducer,
  profile: profileReducer,
  forgotPassword: forgotPasswordReducer,
  cart: cartReducer,
  newOrder: newOrderReducer, //
  myOrders: myOrdersReducer,
  orderDetails: orderDetailsReducer,
  newReview: newReviewReducer,
  newProduct: newProductReducer,
  product: productReducer,
  allOrders: allOrdersReducer,
  order: orderReducer,
  allUsers: allUsersReducer,
  userDetails: userDetailsReducer,
  productReviews: productReviewsReducer,
  review: reviewReducer,
});

let initialState = {
  cart: {
    cartItems: localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : [],
    shippingInfo: localStorage.getItem("shippingInfo")
      ? JSON.parse(localStorage.getItem("shippingInfo"))
      : {},
  },
};

const middleware = [thunk];

const store = createStore(
  reducer, // upar wala reducer hai.
  initialState, // upar wala initialState hai.
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

//Redux ke baare me:>//
// ek store banate hai aur ye store me backend se jo bhi chahiye woh mangate hai aur frontend me jaha bhi zaroorat ho waha use karte hai. webpage par show hone se pahile ye data inspect ke redux tab me jata hai phir hum ise webpage me bulate hai.
// Redux Devtools extensio install karo data ko real time me dekhne keliye inspect tab me dekhne keliye.
// 3 cheeze karte hai redux me, 1.constant, 2.reducer, 3.action.
// pahile store.js banaye, phir index.js.
// // 3 folders banaye: 1.constant, 2.reducer, 3.action.
// 
