
import React, { Fragment, useEffect, useState } from "react";
// import {CgMouse} from 'react-icons/all';
import "./Home.css";
import ProductCard from "./ProductCard.js";
import Offer from "./Offer.js";
import MetaData from "../layout/MetaData";
import { clearErrors, getProduct } from "../../actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../layout/Loader/Loader";
import { useAlert } from "react-alert";


const Home = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, error, products } = useSelector((state) => state.products);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getProduct());
  }, [dispatch, error, alert]);

  return ( //<Fragment> ki jaga par aisa bhi likh sakte hai empty tag:> <></>
    <Fragment> 
      
      {loading ? (
        <Loader />
      ) : (
        <Fragment> 
          <MetaData title="Tz MART" />
          <h2 className="homeHeading">Offers</h2>
          <div className="container" id="container">
            {products && // products naam ka ek document hai ye database se araha hai.
              products.map((product) => (
                <Offer
                  key={product._id}
                  product={product}
                  targetCategory="Offer" // Replace with your actual target category
                />
              ))}
          </div>

          <h2 className="homeHeading">Featured Products</h2>
          <div className="container" id="container">
            {products &&
              products
                .filter((product) => product.category !== "Offer")
                .map((product) => (
                  <ProductCard key={product._id} product={product} />
                ))}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Home;




// import React, { Fragment, useEffect, useState } from "react";
// // import {CgMouse} from 'react-icons/all';
// import "./Home.css";
// import ProductCard from "./ProductCard.js";
// import Offer from "./Offer.js";
// import MetaData from "../layout/MetaData";
// import { clearErrors, getProduct } from "../../actions/productAction";
// import { useSelector, useDispatch } from "react-redux";
// import Loader from "../layout/Loader/Loader";
// import { useAlert } from "react-alert";
// // import "slick-carousel/slick/slick.css";
// // import "slick-carousel/slick/slick-theme.css";
// // import Slider from "react-slick";

// const Home = () => {
//   // const slidee = ["bg1.png", "f1.jpg", "f55.jpeg", "f22.jpg", "f4.jpg"];
//   // // Asssuming you have an array of image filenames in the same directory
//   // const images = [
//   //   "tmartfront.jpg",
//   //   "inside1.jpg",
//   //   "inside3.jpg",
//   //   "inside4.jpg",
//   //   "inside5.jpg",
//   // ];

//   // const [currentSlide, setCurrentSlide] = useState(0);

//   // const sliderSettings = {
//   //   dots: true,
//   //   infinite: true,
//   //   speed: 500,
//   //   slidesToShow: 1,
//   //   slidesToScroll: 1,
//   //   autoplay: true,
//   //   autoplaySpeed: 2000,
//   //   afterChange: (current) => setCurrentSlide(current),
//   // };

//   const alert = useAlert();
//   const dispatch = useDispatch();
//   const { loading, error, products } = useSelector((state) => state.products);

//   useEffect(() => {
//     if (error) {
//       alert.error(error);
//       dispatch(clearErrors());
//     }
//     dispatch(getProduct());
//   }, [dispatch, error, alert]);

//   return (
//     <Fragment>
//       {loading ? (
//         <Loader />
//       ) : (
//         <Fragment>
//           <MetaData title="ECOMMERCE" />
//           {/* <div className="slider-container">
//             <Slider {...sliderSettings}>
//               {slidee.map((imagee, index) => (
//                 <div key={index}>
//                   <div
//                     className="banner"
//                     style={{
//                       backgroundImage: `url(/imagee/${imagee})`,
//                       opacity: index === currentSlide ? 1 : 0,
//                     }}
//                   >
//                     <div className="banner-content">
//                         <p style={{ margin: 0, padding: 0, fontSize: '24px', color: "white",fontWeight: 'bold' }}>
//                           Welcome to Tz MART
//                         </p>
                       
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </Slider>
//           </div> */}

//           <h2 className="homeHeading">Offers</h2>
//           <div className="container" id="container">
//             {products &&
//               products.map((product) => (
//                 <Offer
//                   key={product._id}
//                   product={product}
//                   targetCategory="Offer" // Replace with your actual target category
//                 />
//               ))}
//           </div>

//           <h2 className="homeHeading">Featured Products</h2>
//           <div className="container" id="container">
//             {products &&
//               products
//                 .filter((product) => product.category !== "Offer")
//                 .map((product) => (
//                   <ProductCard key={product._id} product={product} />
//                 ))}
//           </div>

//           {/* <div
//             style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}
//           >
//             <div className="row" style={{ marginBottom: "4px" }}>
//               <div className="col-lg-12">
//                 <div className="section-head-style-one">
//                   <h2>What are you looking for?</h2>
//                   <p>We have a variety of categories available</p>
//                   <Slider {...sliderSettings}>
//                     {images.map((image, index) => (
//                       <img
//                         key={index}
//                         src={`/image/${image}`}
//                         alt={`Image ${index + 1}`}
//                         style={{ width: "10%", height: "10%" }}
//                       />
//                     ))}
//                   </Slider>
//                 </div>
//               </div>
//             </div>
//           </div> */}
//         </Fragment>
//       )}
//     </Fragment>
//   );
// };

// export default Home;
