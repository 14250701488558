import React, { Fragment, useState, useEffect } from "react";
import "./Shipping.css";
import { useSelector, useDispatch } from "react-redux";
import { saveShippingInfo } from "../../actions/cartAction";
import MetaData from "../layout/MetaData";
import PinDropIcon from "@material-ui/icons/PinDrop";
import HomeIcon from "@material-ui/icons/Home";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import PublicIcon from "@material-ui/icons/Public";
import PhoneIcon from "@material-ui/icons/Phone";
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
import { Country, State } from "country-state-city";
import { useAlert } from "react-alert";
import CheckoutSteps from "../Cart/CheckoutSteps";

const Shipping = ({ history }) => { // is ka matlab ye hai k history pull horahi hai kisi dusre file se.
  const dispatch = useDispatch();
  const alert = useAlert();
  const { shippingInfo } = useSelector((state) => state.cart);

  const [address, setAddress] = useState(shippingInfo.address);
  const [city, setCity] = useState(shippingInfo.city);
  const [state, setState] = useState("MH"); // Maharashtra ISO code
  const [country, setCountry] = useState("IN"); // India ISO code
  const [pinCode, setPinCode] = useState(shippingInfo.pinCode);
  const [phoneNo, setPhoneNo] = useState(shippingInfo.phoneNo);

  useEffect(() => {
    // Fetch state name from its ISO code and set default state name
    const defaultState = State.getStateByCode("IN", "MH");
    setState(defaultState ? defaultState.name : "");
  }, []);

  const shippingSubmit = (e) => {
    e.preventDefault();

    if (phoneNo.length < 10 || phoneNo.length > 10) {
      alert.error("Phone Number should be 10 digits Only");
      return;
    }

    if (address.length < 4 || address.length > 15) {
      alert.error("Address should be between 4 and 15 characters long");
      return;
    }
    
    if (city.length < 4 || city.length > 15) {
      alert.error("City should be between 4 and 15 characters long");
      return;
    }

    dispatch(
      saveShippingInfo({ address, city, state, country, pinCode, phoneNo })
    );
    history.push("/order/confirm");
  };

  const handleAddressChange = (e) => {
    const value = e.target.value;
    // Regex to allow only numbers and English text
    const regex = /^[a-zA-Z0-9\s]*$/;
    if (regex.test(value) || value === "") {
      setAddress(value);
    }
  };

  const handleCityChange = (e) => {
    const value = e.target.value;
    // Regex to allow only English text
    const regex = /^[a-zA-Z\s]*$/;
    if (regex.test(value) || value === "") {
      setCity(value);
    }
  };

  return (
    <Fragment>
      <MetaData title="Shipping Details" />

      <CheckoutSteps activeStep={0} />
      {/* initially 0 index par rahenga. */}

      <div className="shippingContainer">
        <div className="shippingBox">
          <h2 className="shippingHeading">Shipping Details</h2>

          <form
            className="shippingForm"
            encType="multipart/form-data"
            onSubmit={shippingSubmit}
          >
            <div>
              <HomeIcon />
              <input
                type="text"
                placeholder="Address"
                required
                value={address}
                onChange={handleAddressChange}
              />
            </div>

            <div>
              <LocationCityIcon />
              <input
                type="text"
                placeholder="City"
                required
                value={city}
                onChange={handleCityChange}
              />
            </div>

            <div>
              <PinDropIcon />
              <input
                type="number"
                placeholder="House Number"
                required
                value={pinCode}
                onChange={(e) => setPinCode(e.target.value)}
              />
            </div>

            <div>
              <PhoneIcon />
              <input
                type="number"
                placeholder="Phone Number"
                required
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
                size="10"
              />
            </div>

            <div>
              <PublicIcon />

              <select
                required
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                disabled
              >
                <option value="IN">India</option>
              </select>
            </div>

            {country && (
              <div>
                <TransferWithinAStationIcon />

                <select
                  required
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                >
                  <option value="MH">Maharashtra</option>
                </select>
              </div>
            )}

            <input
              type="submit"
              value="Continue"
              className="shippingBtn"
              disabled={state ? false : true}
            />
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Shipping;





// import React, { Fragment, useState } from "react";
// import "./Shipping.css";
// import { useSelector, useDispatch } from "react-redux";
// import { saveShippingInfo } from "../../actions/cartAction";
// import MetaData from "../layout/MetaData";
// import PinDropIcon from "@material-ui/icons/PinDrop";
// import HomeIcon from "@material-ui/icons/Home";
// import LocationCityIcon from "@material-ui/icons/LocationCity";
// import PublicIcon from "@material-ui/icons/Public";
// import PhoneIcon from "@material-ui/icons/Phone";
// import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
// import { Country, State } from "country-state-city";
// import { useAlert } from "react-alert";
// import CheckoutSteps from "../Cart/CheckoutSteps";

// const Shipping = ({ history }) => { // is ka matlab ye hai k history pull horahi hai kisi dusre file se.
//   const dispatch = useDispatch();
//   const alert = useAlert();
//   const { shippingInfo } = useSelector((state) => state.cart);

//   const [address, setAddress] = useState(shippingInfo.address);
//   const [city, setCity] = useState(shippingInfo.city);
//   const [state, setState] = useState(shippingInfo.state);
//   const [country, setCountry] = useState(shippingInfo.country);
//   const [pinCode, setPinCode] = useState(shippingInfo.pinCode);
//   const [phoneNo, setPhoneNo] = useState(shippingInfo.phoneNo);

//   const shippingSubmit = (e) => {
//     e.preventDefault();

//     if (phoneNo.length < 10 || phoneNo.length > 10) {
//       alert.error("Phone Number should be 10 digits Only");
//       return;
//     }

//     if (address.length < 4 || address.length > 15) {
//       alert.error("Address should be between 4 and 15 characters long");
//       return;
//     }
    
//     if (city.length < 4 || city.length > 15) {
//       alert.error("City should be between 4 and 15 characters long");
//       return;
//     }

//     dispatch(
//       saveShippingInfo({ address, city, state, country, pinCode, phoneNo })
//     );
//     history.push("/order/confirm");
//   };

//   const handleAddressChange = (e) => {
//     const value = e.target.value;
//     // Regex to allow only numbers and English text
//     const regex = /^[a-zA-Z0-9\s]*$/;
//     if (regex.test(value) || value === "") {
//       setAddress(value);
//     }
//   };

//   const handleCityChange = (e) => {
//     const value = e.target.value;
//     // Regex to allow only English text
//     const regex = /^[a-zA-Z\s]*$/;
//     if (regex.test(value) || value === "") {
//       setCity(value);
//     }
//   };

//   return (
//     <Fragment>
//       <MetaData title="Shipping Details" />

//       <CheckoutSteps activeStep={0} />
//       {/* initially 0 index par rahenga. */}

//       <div className="shippingContainer">
//         <div className="shippingBox">
//           <h2 className="shippingHeading">Shipping Details</h2>

//           <form
//             className="shippingForm"
//             encType="multipart/form-data"
//             onSubmit={shippingSubmit}
//           >
//             <div>
//               <HomeIcon />
//               <input
//                 type="text"
//                 placeholder="Address"
//                 required
//                 value={address}
//                 onChange={handleAddressChange}
//               />
//             </div>

//             <div>
//               <LocationCityIcon />
//               <input
//                 type="text"
//                 placeholder="City"
//                 required
//                 value={city}
//                 onChange={handleCityChange}
//               />
//             </div>

//             <div>
//               <PinDropIcon />
//               <input
//                 type="number"
//                 placeholder="House Number"
//                 required
//                 value={pinCode}
//                 onChange={(e) => setPinCode(e.target.value)}
//               />
//             </div>

//             <div>
//               <PhoneIcon />
//               <input
//                 type="number"
//                 placeholder="Phone Number"
//                 required
//                 value={phoneNo}
//                 onChange={(e) => setPhoneNo(e.target.value)}
//                 size="10"
//               />
//             </div>

//             <div>
//               <PublicIcon />

//               <select
//                 required
//                 value={country}
//                 onChange={(e) => setCountry(e.target.value)}
//               >
//                 <option value="">Country</option>
//                 {Country && //getAllCountries function hai Country npm package ka upar dekho import se araha hai.
//                   Country.getAllCountries().map((item) => ( 
//                     <option key={item.isoCode} value={item.isoCode}>
//                       {item.name}
//                     </option>
//                   ))}
//               </select>
//             </div>

//             {country && ( //agar country choose kiya user ne to state batana choose karne se pahile state nahi batana.
//               <div>
//                 <TransferWithinAStationIcon />

//                 <select
//                   required
//                   value={state}
//                   onChange={(e) => setState(e.target.value)}
//                 >
//                   <option value="">State</option>
//                   {State &&
//                     State.getStatesOfCountry(country).map((item) => (
//                       <option key={item.isoCode} value={item.isoCode}>
//                         {item.name}
//                       </option>
//                     ))}
//                 </select>
//               </div>
//             )}

//             <input
//               type="submit"
//               value="Continue"
//               className="shippingBtn"
//               disabled={state ? false : true}
//             />
//           </form>
//         </div>
//       </div>
//     </Fragment>
//   );
// };

// export default Shipping;