import {
  ADD_TO_CART,
  REMOVE_CART_ITEM,
  SAVE_SHIPPING_INFO,
} from "../constants/cartConstants";
import axios from "axios";

// Add to Cart
export const addItemsToCart = (id, quantity) => async (dispatch, getState) => { //ProductDetails.js me ye wala function call horaha isi liye ise yaha banaye. // ye sare function ise liye bana rahe hai jo k duri jaga call horahe hai jaise ProductDetails.js.
  const { data } = await axios.get(`/api/v1/product/${id}`); //yaha se backend se data araha hai.

  dispatch({
    type: ADD_TO_CART,
    payload: {
      product: data.product._id, //product ke saath id bhi mil gayi object ki shakal me.
      name: data.product.name,
      price: data.product.price,
      image: data.product.images[0].url, //pura database find karne par jo image milengi uss ki pahila array means [0] kyunki ek naam ki product pure database me ek hi hongi. 
      stock: data.product.Stock,
      quantity,
    },
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems)); //webpage ko reload karne ke baad state mese product jana nahi chahiye, jab tak user product ko cart mese remove nahi karta localStorage me save rahena.
};

// REMOVE FROM CART
export const removeItemsFromCart = (id) => async (dispatch, getState) => {
  dispatch({
    type: REMOVE_CART_ITEM,
    payload: id,
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

// SAVE SHIPPING INFO
export const saveShippingInfo = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_SHIPPING_INFO,
    payload: data,
  });

  localStorage.setItem("shippingInfo", JSON.stringify(data));
};
