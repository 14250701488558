import React from "react";
import { Link } from "react-router-dom";
import { Rating } from "@material-ui/lab";
import AddToCartButton from "./AddToCartButton";

const ProductCard = ({ product }) => { //Home.js me dekho yaha se product araha hai <ProductCard key={product._id} product={product} /> 
  const options = {
    value: product.ratings,
    readOnly: true,
    precision: 0.5,
  };
  return (
  //   <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
  //   <Link className="productCard" to={`/product/${product._id}`}>
  //     <img src={product.images[0].url} alt={product.name} />
  //     <p>{product.name}</p>
  //     {/* <div>
  //       <Rating {...options} />{" "}
  //       <span className="productCardSpan">
  //         {" "}
  //         ({product.numOfReviews} Reviews)
  //       </span>
  //     </div> */}
  //     <span>MRP= <del>{`₹${product.ourprice}`}</del></span>
  //     <a>Tz MART= {`₹${product.price}`}</a>
  //     {/* Adding AddToCartButton inside Link */}
  //     <AddToCartButton productId={product._id} style={{ marginTop: "0px"  }} />
  //   </Link>
  // </div>

  <div className="productCard" style={{ position: "relative", display: "flex", flexDirection: "column", alignItems: "center" }}>
 
    <img src={product.images[0].url} alt={product.name} /> 
     {/* <Link className="productCard" to={`/product/${product._id}`}> */}
     {product.offerr && ( // Check if there is an offer
     // [0] means array ki pahili image. .url means image ke ander url hai.
      <div style={{ 
        background: "red", 
        color: "white", 
        padding: "1px 2px",
        width: "40px",
        height: "14px",
        textAlign: "center",
        fontSize: "8px", // Adjust font size as needed
        borderRadius: "4px", // Add border radius for better appearance
        zIndex: 999, // Ensure the offer text appears above other content
      }}>
        {product.offerr} SAVE
      </div>
    )}
    <p style={{ fontSize: '1vmax' }}>{product.name}</p>
    <span style={{ fontSize: '0.9vmax' }}>MRP= <del>{`₹${product.ourprice}`}</del></span>
    <a style={{ fontSize: '0.9vmax' }}> Tz MART= {`₹${product.price}`}</a>
  {/* </Link> */}
  <AddToCartButton productId={product._id} style={{ marginTop: "0px" }} />
</div>
  );
};

export default ProductCard;
