import React, { Fragment, useEffect } from "react";
import "./Home.css";
import Offer from "./Offer.js";
import { clearErrors, getProduct } from "../../actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../layout/Loader/Loader";
import { useAlert } from "react-alert";

const Categorywise = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, error, products } = useSelector((state) => state.products);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getProduct());
  }, [dispatch, error, alert]);

 

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <h2 className="homeHeading" style={{ fontWeight: "bold" }}>Daily Needs</h2>
          <div className="container" id="container">
            {products &&
              products.map((product) => (
                <Offer
                  key={product._id}
                  product={product}
                  targetCategory="DailyNeed Items" // Replace with your actual target category
                />
              ))}
          </div>


          <h2 className="homeHeading" style={{ fontWeight: "bold" }}>Dry fruits</h2>
          <div className="container" id="container">
            {products &&
              products.map((product) => (
                <Offer
                  key={product._id}
                  product={product}
                  targetCategory="Dryfruit" // Replace with your actual target category
                />
              ))}
          </div>



          <h2 className="homeHeading" style={{ fontWeight: "bold" }}>Plastics</h2>
          <div className="container" id="container">
            {products &&
              products.map((product) => (
                <Offer
                  key={product._id}
                  product={product}
                  targetCategory="Plastics Appliance" // Replace with your actual target category
                />
              ))}
          </div>



          <h2 className="homeHeading" style={{ fontWeight: "bold" }}>Cosmetics</h2>
          <div className="container" id="container">
            {products &&
              products.map((product) => (
                <Offer
                  key={product._id}
                  product={product}
                  targetCategory="Cosmetic" // Replace with your actual target category
                />
              ))}
          </div>


          <h2 className="homeHeading" style={{ fontWeight: "bold" }}>Baby</h2>
          <div className="container" id="container">
            {products &&
              products.map((product) => (
                <Offer
                  key={product._id}
                  product={product}
                  targetCategory="Baby" // Replace with your actual target category
                />
              ))}
          </div>

          <h2 className="homeHeading" style={{ fontWeight: "bold" }}>Groceries</h2>
          <div className="container" id="container">
            {products &&
              products.map((product) => (
                <Offer
                  key={product._id}
                  product={product}
                  targetCategory="Grocery" // Replace with your actual target category
                />
              ))}
          </div>
          
          <h2 className="homeHeading" style={{ fontWeight: "bold" }}>Crockery</h2>
          <div className="container" id="container">
            {products &&
              products.map((product) => (
                <Offer
                  key={product._id}
                  product={product}
                  targetCategory="Crockery" // Replace with your actual target category
                />
              ))}
          </div>

          <h2 className="homeHeading" style={{ fontWeight: "bold" }}>toys</h2>
          <div className="container" id="container">
            {products &&
              products.map((product) => (
                <Offer
                  key={product._id}
                  product={product}
                  targetCategory="toys" // Replace with your actual target category
                />
              ))}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Categorywise;
